import React from '../../../../node_modules/react';
import '../../../App.css';

const titleH2 = {
  margin: '-15px -20px',
  padding: '15px 20px 15px 20px',
  fontSize: '1.2em',
  lineHeight: '1.1',
  letterSpacing: '-.02em'
}

const iconSpan = {
  paddingRight: '10px'
}

class Draw extends React.Component {
  constructor(props) {
    super(props);

    this.closePanel = this.closePanel.bind(this);
    this.usePoly = this.usePoly.bind(this);
  }

  componentDidMount() {
    const panels = document.getElementsByClassName('sidePanelStyle');
    for (let i = 0; i < panels.length; i++) {
      panels[i].style.backgroundColor = 'rgba(255,255,255,0)';
      panels[i].style.pointerEvents = 'none';
    }

    this.props.updateMapDrawPt(true);
  }

  closePanel() {
    this.props.togglePanel('draw');
  }

  componentWillUnmount() {
    const panels = document.getElementsByClassName('sidePanelStyle');
    for (let i = 0; i < panels.length; i++) {
      panels[i].style.backgroundColor = '#ffffff';
      panels[i].style.pointerEvents = 'unset';
    }

    this.props.updateMapDrawPt(false);
  }

  usePoly() {
    this.props.setMethodType('none');
    this.props.updateReportName('Custom Polygon');
    this.props.showFilters();
  }

  render() {
    return (
      <div>
        <h2 style={titleH2}>
          <span style={iconSpan}>
            <svg id="squaresIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4187" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.29-4.79v-31.3h-60v60h31.3" transform="translate(36.96 37.34)" />
              <path id="Path_4188" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.65,23.67V55.91H56.29V-5H24.05" transform="translate(36.96 37.34)" />
              <path id="Path_4189" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M3.5,23.76h-8v-8" transform="translate(36.96 37.34)" />
              <path id="Path_4191" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.5,3.12v-8h8" transform="translate(36.96 37.34)" />
              <path id="Path_4193" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M16.14-4.88h8v8" transform="translate(36.96 37.34)" />
              <path id="Path_4195" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.14,15.76v8h-8" transform="translate(36.96 37.34)" />
            </svg>
          </span>
          Draw a Polygon</h2>
        <div className="drawPanelText">
          Use these buttons to draw, edit, or delete:
          </div>
          {
          this.props.polys.length > 0 ?
            <div className="geocodeDiv flexRight showPolyBtn">
              <button className="geocodeBtn pointerAuto" onClick={this.usePoly}>Use This Poly</button>
            </div>
            : null
        }
      </div>
    )
  }
}

export default Draw;