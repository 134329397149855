import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from '@material-ui/core/Modal';
import ReportListComponent from '../ReportListComponent/ReportListComponent';
import FAQComponent from '../FAQComponent/FAQComponent';
import MethodologyComponent from '../MethodologyComponent/MethodologyComponent';
import chainPDF from '../../Assets/CHAIN_LIST.pdf';
import trVntPDF from '../../Assets/TR_vs_NT.pdf';
import storeGradePDF from '../../Assets/StoreGRADE_Metadata.pdf';
import pandemicPDF from '../../Assets/Pandemic_Stmt.pdf';
import sampleReport from '../../Assets/SampleReport.pdf';
import sampleMap from '../../Assets/SampleMap.pdf';
import VideoComponent from '../VideoComponent/VideoComponent';

const mainTextDiv = {
  color: '#4a4a4d'
}

class SupportMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalClosed: true,
      modalSrc: ''
    }

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalOpen(reportName) {
    //const src = 'https://hydra.colliers.com/assets/samples/' + reportName;
    const src = reportName;
    this.setState({
      modalOpen: true,
      modalClosed: false,
      modalSrc: src
    });
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
      modalClosed: true
    });
  }

  render() {
    // const handleChange = panel => (event, newExpanded) => {
    //   //setExpanded(newExpanded ? panel : false);
    // };

    return (
      <div style={mainTextDiv}>
        <div className="reportsAccordionDiv">
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="sectionHeader" id="panel1a-header">
              Data source and methodology
            </AccordionSummary>
            <AccordionDetails>
              <div className="panel-divider"></div>
              <div className="method-container">
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="Restaurant Trends"
                    id="a"
                    description="Restaurant Trends data measures the performance of stores through annualized sales, as well as indexed Market and National grades. Reports created from this data are simple and easy to understand with grades A+ to F."
                    url="https://www.restauranttrends.com"
                    buttonText="View Website"
                  />
                </div>
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="List of Chains"
                    id="b"
                    description="A list of all FSR and QSR Chains available from Restaurant Trends, sorted by Category."
                    url={chainPDF}
                    buttonText="View Document"
                  />
                </div>
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="Pandemic Statement"
                    id="h"
                    description="Pandemic Statement"
                    url={pandemicPDF}
                    buttonText="View Document"
                  />
                </div>
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="TR/NT Definitions"
                    id="c"
                    description="This document provides information on Traditional vs. Non-Traditional store types."
                    url={trVntPDF}
                    buttonText="View Document"
                  />
                </div>
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="StoreGRADE Metadata"
                    id="d"
                    description="Definitions and information various fields available for each data record."
                    url={storeGradePDF}
                    buttonText="View Document"
                  />
                </div>
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="Methodology"
                    id="e"
                    description="In general Restaurant Trends obtains their research from working in cooperation directly with QSR and FSR chains and individual stores.  The restaurant industry is researched as a 100% census and the data is not based on an incomplete sample.  Store level sales are constantly verified and updated, with major chain restaurants researched in detail every 18-24 months with a goal of obtaining three separate sales data points in a given five year period.  Numerous secondary sources, including sales tax data and county documents and publications are also reviewed and verified."
                    buttonText="none"
                  />
                </div>
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="Segment &amp; Category Hierarchy"
                    id="f"
                    buttonText="hierarchyTable"
                  />
                </div>
                <div className="padding5">
                  <MethodologyComponent
                    methodTitle="Variable Definitions"
                    id="g"
                    buttonText="variableTable"
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modalOpen}
            onClose={this.handleModalClose}
          >
            <div className="modalStyle">
              <div className="modalCloseDiv">
                <button onClick={this.handleModalClose} className="modalCloseButton">X</button>
              </div>
              <iframe className="modalIframe" title="sample report" src={this.state.modalSrc} frameBorder="0"></iframe>
            </div>
          </Modal>

          <div className="panel-divider"></div>

          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" className="sectionHeader" id="panel1c-header">
              Sample reports
            </AccordionSummary>
            <AccordionDetails>
              <div className="panel-divider"></div>
              <div>
                <div className="method-grid-row">
                  <ReportListComponent
                    reportTitle={'Report'}
                    reportDescription={' - A Colliers branded PDF showing Restaurant Trends data in table format.'}
                    handleClick={this.handleModalOpen.bind(this, sampleReport)}
                  />
                </div>
                <div className="method-grid-row">
                  <ReportListComponent
                    reportTitle={'Map'}
                    reportDescription={' - A Colliers branded PDF showing Restaurant Trends data on a map.'}
                    handleClick={this.handleModalOpen.bind(this, sampleMap)}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <div className="panel-divider"></div>

          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1f-content" className="sectionHeader" id="panel1f-header">
              FAQs
            </AccordionSummary>
            <AccordionDetails>
              <div className="method-container">
                <div className="method-grid-row">
                  <FAQComponent
                    id="a"
                    question="How do I get access to Restaurant Bytes?"
                    answer="All Colliers professionals have access to Restaurant Bytes.  You can go directly to Restaurant Bytes using bytes.colliers.com, or you can also find it on my.colliers, the USA Colliers Hub, and soon on Colliers Atlas."
                  ></FAQComponent>
                  <FAQComponent
                    id="b"
                    question="Is Restaurant Bytes supported on all internet browsers?"
                    answer="Chrome is preferred, however currently Restaurant Bytes is supported on Chrome and Edge browsers.  We are working on implementing support on Firefox and Internet Explorer.  Restaurant Bytes will also work on mobile device browsers."
                  ></FAQComponent>
                </div>
                <div className="method-grid-row">
                  <FAQComponent
                    id="c"
                    question="Can you type an intersection instead of an address, and what is the correct format for entering intersections?"
                    answer="Yes you can enter an intersection while using the ‘address’ method to enter a location.  Separate the two streets with either ‘&amp;’ or ‘and’.  It’s always best if the street names are entered complete with directional and suffix."
                  ></FAQComponent>
                  <FAQComponent
                    id="d"
                    question="What is the correct format when I’m entering an address?"
                    answer="Ideally you want to enter as complete a street address as possible including directional prefix and street type suffix.  Restaurant Bytes will often be able to figure out or give you choices based on an incomplete entry, but for best results be as complete as possible."
                  ></FAQComponent>
                </div>
                <div className="method-grid-row">
                  <FAQComponent
                    id="e"
                    question="What is a standard geography?"
                    answer="Standard geographies are polygons based on official governmental boundaries such as states, counties, zip codes, places (cities), CBSA (MSA), census tracts, and census block groups."
                  ></FAQComponent>
                  <FAQComponent
                    id="f"
                    question="Is there a limit to the number of records on a report?"
                    answer="Yes reports are limited to 200 records."
                  ></FAQComponent>
                </div>
                <div className="method-grid-row">
                  <FAQComponent
                    id="g"
                    question="How do filters work in Restaurant Bytes?"
                    answer="On the filter panel, the dropdown menus for Segement, Subsegment, Category, and Chain will populate with any available options within the geography selected.  If there are no options that match the criteria, no options will appear in the dropdown.  For example, if a three mile radius around an address is selected, options in the dropdowns will represent only values available within the three mile radius."
                  ></FAQComponent>
                  <FAQComponent
                    id="h"
                    question="What should I do if I think there is inaccurate sales information for any records in Restaurant Bytes?"
                    answer=""
                  ></FAQComponent>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <div className="panel-divider"></div>

          <Accordion defaultExpanded={true} square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1b-content" className="sectionHeader" id="panel1b-header">
              Training materials
            </AccordionSummary>
            <AccordionDetails>
              <div className="method-container">
                <div className="method-grid-row">
                  <VideoComponent
                    title={'Bytes training 6/11'}
                    src="http://portal.atlas.colliers.com/videos/Bytes_Training_June_11.mp4"
                    type="video/mp4"
                  />
                  <VideoComponent
                    title={'Explore the map and create report'}
                    src="https://portal.atlas.colliers.com/videos/Explore%20Map%20Report.mov"
                  />
                  <VideoComponent
                    title={'Create a report using address'}
                    src="https://portal.atlas.colliers.com/videos/Address%20Report.mov"
                  />
                  <VideoComponent
                    title={'Create a report using polygon'}
                    src="https://portal.atlas.colliers.com/videos/Restaurant%20Bytes%20Polygon%20Search.mov"
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

        </div>
      </div >
    );
  }
}

export default SupportMain;