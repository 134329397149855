import React from '../../../../node_modules/react';
import '../../../App.css';
import axios from '../../../../node_modules/axios';
import TextField from '../../../../node_modules/@material-ui/core/TextField';

const ulStyle = {
  listStyleType: 'none',
  margin: 0,
  padding: '6px 0px 0px 0px',
}

class SearchAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestData: [],
      searchVal: '',
      magicKeyVal: '',
      showSearchBtn: false,
      showErrorMessage: false
    }

    this.geocode = this.geocode.bind(this);
  }

  getSuggestions(e) {
    this.setState({
      searchVal: e.target.value,
      showSearchBtn: false
    })
    axios.get(process.env.REACT_APP_GEOCODEURL + '/suggest?text=' + e.target.value + '&f=pjson')
      .then(result => {
        if (result && result.data) {
          this.setState({
            suggestData: result.data.suggestions,
          });
        }
      });
  }

  setSearchValue(e, suggestion) {
    this.setState({
      searchVal: suggestion.text,
      magicKeyVal: suggestion.magicKey,
      suggestData: [],
      showSearchBtn: true,
      filtersShow: false
    });
  }

  geocode() {
    axios.get(process.env.REACT_APP_GEOCODEURL + '/findAddressCandidates?SingeLine=' + this.state.searchVal + '&magicKey=' + this.state.magicKeyVal + '&f=pjson')
      .then(result => {
        if (result && result.data && result.data.candidates) {
          if (result.data.candidates.length > 0) {
            const newPointData = result.data.candidates[0];
            const pointsArray = [{
              position: { lat: newPointData.location.y, lng: newPointData.location.x },
              content: {
                id: '1234',
              }
            }];

            this.setState({
              showErrorMessage: false,
            });
            this.props.updateMapCenter(pointsArray[0].position);
          }
        }
      }).catch(error => {
        console.log(error);
        if (error) {
          this.setState({
            showErrorMessage: true,
          });
        }
      });
  }

  render() {
    return (
      <div className="searchPanelStyle">
        <div className="exploreInput">
          <TextField
            id="explore-input"
            label="Find address or place"
            variant="outlined"
            value={this.state.searchVal}
            size="small"
            fullWidth
            onChange={(e) => { this.getSuggestions(e) }}
          />

          {this.state.showSearchBtn ?
            <button className="geocodeBtn" onClick={this.geocode}>Search</button>
            : null
          }
        </div>
        {this.state.suggestData ?
          <ul style={ulStyle}>
            {this.state.suggestData.map(suggestion => (
              <li
                className="search"
                id={suggestion.magicKey}
                key={suggestion.magicKey}
                value={suggestion.text}
                onClick={(e) => { this.setSearchValue(e, suggestion) }}
              >
                {suggestion.text}
              </li>
            ))}
          </ul>
          : null}
        {this.state.showErrorMessage ?
          <div className="errorText">
            Oops!  Something went wrong.  Please try again.
          </div>
          : null}
      </div>
    )
  }
}

export default SearchAddress;