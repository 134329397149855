import React from '../../../../node_modules/react';
import '../../../App.css';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class AutoCompFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: []
    }

    this.onTagsChange = this.onTagsChange.bind(this);
    this.onTagRemoved = this.onTagRemoved.bind(this);
  }

  componentDidUpdate() {
    const self = this;
    let valueToRemove = [];

    let arrayEqual = this.state.tags.length === this.props.selections.length && this.state.tags.every(item => this.props.selections.indexOf(item) > -1);
    
    if (arrayEqual === false) {
      if (this.state.tags.length > this.props.selections.length) {
        const newTagArray = self.state.tags.filter(value => self.props.selections.includes(value));
        this.setState({
          tags: newTagArray
        });
      } else {
        valueToRemove = this.props.selections.filter(function(item) {
          return !self.state.tags.includes(item);
        });
        
        let updateArray = [];
        valueToRemove.forEach(item => {
          updateArray.push({type: this.props.type, add: false, value: item});
        })
        this.props.updateFilterVal(updateArray);
      }
    }
  }

  checkFilterOption(e, option) {
    this.props.getNewQuery(this.props.type, e.target.checked, option);
  }

  onClear(event, value, reason, type) {
    if (reason === 'clear') {
      this.props.onClear(type);
    }
  }

  onTagsChange(event, values) {
    this.setState({
      tags: values
    });

    if (event.target.className && event.target.className.baseVal === "MuiSvgIcon-root MuiChip-deleteIcon MuiChip-deleteIconSmall")
      this.onTagRemoved(this.props.type, values);
    else if (event.target.ownerSVGElement && event.target.ownerSVGElement.className.baseVal === "MuiSvgIcon-root MuiChip-deleteIcon MuiChip-deleteIconSmall") {
      this.onTagRemoved(this.props.type, values);
    } else if (event.nativeEvent && event.nativeEvent.key && event.nativeEvent.key === "Backspace") {
      this.onTagRemoved(this.props.type, values);
    }
  }

  onTagRemoved(type, newArray) {
    this.props.onTagRemoved(type, newArray);
  }

  render() {
    return (
      <Autocomplete
        multiple
        id={this.props.id}
        value={this.state.tags}
        size="small"
        variant="outlined"
        options={this.props.options}
        disableCloseOnSelect
        onInputChange={(event, value, reason) => this.onClear(event, value, reason, this.props.type)}
        className="autoCompOuterDiv"
        getOptionLabel={option => option}
        onChange={this.onTagsChange}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              onChange={(e) => this.checkFilterOption(e, option, this.props.label)}
            />
            {option}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            label={this.props.label}
            placeholder="Type to search"
            className="autoCompWidth"
          />
        )}
      />
    )
  }
}

export default AutoCompFilters;