import React from '../../../../node_modules/react';
import '../../../App.css';
import axios from '../../../../node_modules/axios';
import TextField from '../../../../node_modules/@material-ui/core/TextField';

const titleH2 = {
  margin: '-15px -20px',
  padding: '15px 20px 15px 20px',
  fontSize: '1.2em',
  lineHeight: '1.1',
  letterSpacing: '-.02em'
}

const textAreaDiv = {
  paddingTop: '15px',
  marginBottom: '14px'
}

const iconSpan = {
  paddingRight: '10px'
}

const ulStyle = {
  listStyleType: 'none',
  margin: 0,
  padding: '6px 0px 0px 0px',
}

class TypeAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestData: [],
      searchVal: '',
      magicKeyVal: '',
      showSearchBtn: false,
      showErrorMessage: false
    }

    this.geocode = this.geocode.bind(this);
  }

  getSuggestions(e) {
    this.setState({
      searchVal: e.target.value,
      showSearchBtn: false
    })
    axios.get(process.env.REACT_APP_GEOCODEURL + '/suggest?text=' + e.target.value + '&f=pjson')
      .then(result => {
        if (result && result.data) {
          this.setState({
            suggestData: result.data.suggestions,
          });
        }
      });
  }

  setSearchValue(e, suggestion) {
    this.setState({
      searchVal: suggestion.text,
      magicKeyVal: suggestion.magicKey,
      suggestData: [],
      showSearchBtn: true,
      filtersShow: false
    });
  }

  geocode() {
    axios.get(process.env.REACT_APP_GEOCODEURL + '/findAddressCandidates?SingeLine=' + this.state.searchVal + '&magicKey=' + this.state.magicKeyVal + '&f=pjson')
      .then(result => {
        if (result && result.data && result.data.candidates) {
          if (result.data.candidates.length > 0) {
            const newPointData = result.data.candidates[0];
            const pointsArray = [{
              position: { lat: newPointData.location.y, lng: newPointData.location.x },
              content: {
                id: '1234',
              }
            }];

            this.setState({
              showErrorMessage: false,
            });
            this.props.updatePointsArray(pointsArray);
            this.props.updateMapCenter(pointsArray[0].position);
            this.props.updateMapZoom(12);
            this.props.showRadiusSection();
            this.props.updateReportName(newPointData.address);
            this.props.updateReportAddress(newPointData.address);
            this.props.setMethodType('none');
          }
        }
      }).catch(error => {
        if (error.response.status === 400 || error.response.status === 404) {
          this.setState({
            showErrorMessage: true,
          });
        }
      });
  }

  render() {
    return (
      <div className="overflowHidden">
        <h2 style={titleH2}>
          <span style={iconSpan}>
            <svg id="globeIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 92.15 92.15">
              <path id="Path_4355" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65,54.74A44.84,44.84,0,1,1,39.76,43.12" transform="translate(36.42 36.16)" />
              <path id="Path_4356" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M10.22-34.91c12.11,0,21.92,20.07,21.92,44.82S22.33,54.74,10.22,54.74" transform="translate(36.42 36.16)" />
              <path id="Path_4357" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.19-34.91c-12.1,0-21.92,20.07-21.92,44.82S-2.91,54.74,9.19,54.74" transform="translate(36.42 36.16)" />
              <path id="Path_4358" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65-36.06v92" transform="translate(36.42 36.16)" />
              <path id="Path_4359" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M54.44,11.72H-35.17" transform="translate(36.42 36.16)" />
              <path id="Path_4360" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M50.74-8.21H-31.44" transform="translate(36.42 36.16)" />
              <path id="Path_4361" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M49,31.64H-29.69" transform="translate(36.42 36.16)" />
            </svg>
          </span>
          Type Address</h2>
        <div style={textAreaDiv}>
          <TextField
            id="search-input"
            label="Find address or place"
            variant="outlined"
            value={this.state.searchVal}
            size="small"
            fullWidth
            onChange={(e) => { this.getSuggestions(e) }}
          />
          {this.state.suggestData ?
            <ul style={ulStyle}>
              {this.state.suggestData.map(suggestion => (
                <li
                  className="search"
                  id={suggestion.magicKey}
                  key={suggestion.magicKey}
                  value={suggestion.text}
                  onClick={(e) => { this.setSearchValue(e, suggestion) }}
                >
                  {suggestion.text}
                </li>
              ))}
            </ul>
            : null}
          {this.state.showSearchBtn ?
            <div className="geocodeDiv flexRight">
              <button className="geocodeBtn" onClick={this.geocode}>Search</button>
            </div>
            : null
          }
           {this.state.showErrorMessage ?
          <div>
            Oops!  Something went wrong.  Please try again.
          </div>
          : null}
        </div>
      </div>
    )
  }
}

export default TypeAddress;