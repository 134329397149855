import React from '../../../node_modules/react';
import PanelButton from '../PanelButton/PanelButton';
import GetReport from './GetReport/GetReport';
import Legend from './Legend/Legend';
import OpeningBtns from '../OpeningBtns/OpeningBtns';
import SearchAddress from './SearchAddress/SearchAddress';

const buttonHolderDiv = {
  paddingTop: '10px',
}

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAddressShow: false,
      drawShow: false,
      clickShow: false,
      legendShow: true,
      geogShow: false,
      printShow: false,
      exploreShow: false,
      addressBtnColor: '#25408f',
      drawBtnColor: '#25408f',
      clickBtnColor: '#25408f',
      geogBtnColor: '#25408f',
      exploreBtnColor: '#25408f',
      printBtnColor: '#25408f',
      legendBtnColor: '#25408f',
      startOverBtnColor: '#25408f',
      showOpeningBtns: true
    };

    this.togglePanel = this.togglePanel.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleHoverOff = this.handleHoverOff.bind(this);
    this.updateOpeningBtns = this.updateOpeningBtns.bind(this);
    this.updateExplore = this.updateExplore.bind(this);
  }

  togglePanel(expr) {
    switch (expr) {
      case 'searchAddress':
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: !this.state.searchAddressShow,
          drawShow: false,
          clickShow: false,
          geogShow: false,
          printShow: false,
          exploreShow: false
        });
        break;
      case 'draw':
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: false,
          drawShow: !this.state.drawShow,
          clickShow: false,
          geogShow: false,
          printShow: false,
          exploreShow: false
        });
        break;
      case 'click':
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: false,
          drawShow: false,
          clickShow: !this.state.clickShow,
          geogShow: false,
          printShow: false,
          exploreShow: false
        });
        break;
      case 'geog':
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: false,
          drawShow: false,
          clickShow: false,
          geogShow: !this.state.geogShow,
          printShow: false,
          exploreShow: false
        });
        break;
      case 'print':
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: false,
          drawShow: false,
          clickShow: false,
          geogShow: false,
          printShow: !this.state.printShow,
          exploreShow: false
        });
        break;
      case 'explore':
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: false,
          drawShow: false,
          clickShow: false,
          geogShow: false,
          printShow: false,
          exploreShow: !this.state.exploreShow
        });
        break;
      case 'legend':
        this.setState({
          legendShow: !this.state.legendShow
        });
        break;
      case 'startOver':
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: false,
          drawShow: false,
          clickShow: false,
          geogShow: false,
          printShow: false,
          legendShow: true,
          exploreShow: false,
          showOpeningBtns: true
        });
        break;
      default:
        this.props.updateEditControlLayer({});
        this.props.updateMapRestPoint(false);
        this.props.updateDriveTime(false);
        this.setState({
          searchAddressShow: false,
          drawShow: false,
          clickShow: false,
          geogShow: false,
          printShow: false,
          legendShow: true,
          exploreShow: false
        });
    }
  }

  handleHover(button) {
    switch (button) {
      case 'address':
        this.setState({
          addressBtnColor: '#0c9ed9'
        })
        break;
      case 'draw':
        this.setState({
          drawBtnColor: '#0c9ed9'
        })
        break;
      case 'click':
        this.setState({
          clickBtnColor: '#0c9ed9'
        })
        break;
      case 'geog':
        this.setState({
          geogBtnColor: '#0c9ed9'
        })
        break;
      case 'print':
        this.setState({
          printBtnColor: '#0c9ed9'
        })
        break;
      case 'legend':
        this.setState({
          legendBtnColor: '#0c9ed9'
        })
        break;
      case 'explore':
        this.setState({
          exploreBtnColor: '#0c9ed9'
        })
        break;
      case 'startOver':
        this.setState({
          startOverBtnColor: '#0c9ed9'
        })
        break;
      default:
        this.setState({
          addressBtnColor: '#25408f',
          drawBtnColor: '#25408f',
          clickBtnColor: '#25408f',
          geogBtnColor: '#25408f',
          exploreBtnColor: '#25408f',
          printBtnColor: '#25408f',
          legendBtnColor: '#25408f',
          startOverBtnColor: '#25408f'
        })
        break;
    }
  }

  handleHoverOff(button) {
    switch (button) {
      case 'address':
        this.setState({
          addressBtnColor: '#25408f'
        })
        break;
      case 'draw':
        this.setState({
          drawBtnColor: '#25408f'
        })
        break;
      case 'click':
        this.setState({
          clickBtnColor: '#25408f'
        });

        break;
      case 'geog':
        this.setState({
          geogBtnColor: '#25408f'
        })
        break;
      case 'explore':
        this.setState({
          exploreBtnColor: '#25408f'
        })
        break;
      case 'print':
        this.setState({
          printBtnColor: '#25408f'
        })
        break;
      case 'legend':
        this.setState({
          legendBtnColor: '#25408f'
        })
        break;
      case 'startOver':
        this.setState({
          startOverBtnColor: '#25408f'
        })
        break;
      default:
        this.setState({
          addressBtnColor: '#25408f',
          drawBtnColor: '#25408f',
          clickBtnColor: '#25408f',
          geogBtnColor: '#25408f',
          exploreBtnColor: '#25408f',
          printBtnColor: '#25408f',
          legendBtnColor: '#25408f',
          startOverBtnColor: '#25408f'
        })
        break;
    }
  }

  updateOpeningBtns(bool) {
    this.setState({
      showOpeningBtns: bool
    });
  }

  updateExplore(bool) {
    this.setState({
      exploreShow: bool
    });
  }

  render() {
    return (
      <div className="sideBarDiv">
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'searchAddress')}
            handleHoverOff={this.handleHoverOff.bind(this, 'address')}
            handleHover={this.handleHover.bind(this, 'address')}
          >
            <svg id="searchIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 85.9 94.5">
              <path id="Path_3886" stroke={this.state.addressBtnColor} fill="none" strokeWidth="4" d="M35.7-36.09v16h16"
                transform="translate(32.96 37.34)" />
              <path id="Path_3887" fill="none" stroke={this.state.addressBtnColor} strokeWidth="4"
                d="M-16.3-22.12V-32.86a3.2,3.2,0,0,1,3.15-3.23H35.7a2.33,2.33,0,0,1,1.8.94L50.75-22.07a2.58,2.58,0,0,1,1,2v72.8a3.2,3.2,0,0,1-3.17,3.2H-13.15a3.18,3.18,0,0,1-3.15-3.2V42.07"
                transform="translate(32.96 37.34)" />
              <path id="Path_3888" stroke={this.state.addressBtnColor} fill="none" strokeWidth="4"
                d="M3.6,27.41A19.74,19.74,0,1,0-16.13,7.67,19.74,19.74,0,0,0,3.6,27.41Z"
                transform="translate(32.96 37.34)" />
              <path id="Path_3889" stroke={this.state.addressBtnColor} fill="none" strokeWidth="4" d="M-10.34,21.62-32.07,43.35"
                transform="translate(32.96 37.34)" />
            </svg>
            <div className="sideBarBtnTxt">ADDRESS</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'click')}
            handleHoverOff={this.handleHoverOff.bind(this, 'click')}
            handleHover={this.handleHover.bind(this, 'click')}
          >
            <svg id="clickIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 94.5 93.99">
              <path id="Path_4109" fill="none" stroke={this.state.clickBtnColor} strokeWidth="4" d="M52.11,29l3.88.73V-31.65L31.45-35.78,7.76-24.86l-20-9.21L-36-25V36.31l23.8-9L2.13,33.75" transform="translate(37.26 37.08)" />
              <path id="Path_4110" fill="none" stroke={this.state.clickBtnColor} strokeWidth="4" d="M-12.21-22.44V16" transform="translate(37.26 37.08)" />
              <path id="Path_4111" fill="none" stroke={this.state.clickBtnColor} strokeWidth="4" d="M7.76-14.76V-.09" transform="translate(37.26 37.08)" />
              <path id="Path_4112" fill="none" stroke={this.state.clickBtnColor} strokeWidth="4" d="M31.88-25.17V-5.74" transform="translate(37.26 37.08)" />
              <path id="Path_4114" fill="none" stroke={this.state.clickBtnColor} strokeWidth="4" d="M25.86,24.24a4.12,4.12,0,1,0-4.15-4.12,4.12,4.12,0,0,0,4.15,4.12Z" transform="translate(37.26 37.08)" />
              <path id="Path_4115" fill="none" stroke={this.state.clickBtnColor} strokeWidth="4" d="M26,56.22S9.21,30.88,9.21,21.67A16.71,16.71,0,0,1,26,5c8.75,0,17.27,7.8,16.76,16.67-.25,4.34-2.94,10.4-5.57,15.6-2.47,4.89-4.88,8.84-4.88,8.84" transform="translate(37.26 37.08)" />
            </svg>
            <div className="sideBarBtnTxt">PIN</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'draw')}
            handleHoverOff={this.handleHoverOff.bind(this, 'draw')}
            handleHover={this.handleHover.bind(this, 'draw')}
          >
            <svg id="drawIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 88.62 94.1">
              <line id="Line_20" stroke={this.state.drawBtnColor} fill="none" strokeWidth="4" y1="92.85" x2="88.62" y2="92.85" />
              <path id="Path_4362" fill="none" stroke={this.state.drawBtnColor} strokeWidth="4" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
              <path id="Path_4363" fill="none" stroke={this.state.drawBtnColor} strokeWidth="4" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
              <circle id="Ellipse_2" fill="none" stroke={this.state.drawBtnColor} strokeWidth="4" cx="33.22" cy="52.86" r="5.67" />
              <line id="Line_21" fill="none" stroke={this.state.drawBtnColor} strokeWidth="4" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
            </svg>
            <div className="sideBarBtnTxt">DRAW POLYGON</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'geog')}
            handleHoverOff={this.handleHoverOff.bind(this, 'geog')}
            handleHover={this.handleHover.bind(this, 'geog')}
          >
            <svg id="geogIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 90.38 86.3">
              <path id="Path_4389" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M-22.63-.24l.06-2.5,4.33-5,4.44.07L-.71-10.29-.77-7.86-2.85-5.23,1.27-.11l-8.6,10.36-.06,4.87,8.6,2.5L5.7,22.73,3.51,28.29,1.42,30.35-.72,43.15l-4.6-2.56L-7.19,28l-4.49-5.24,2.14-5.06L-16,10.06-18.35-.12Z" transform="translate(32.71 31.41)" />
              <path id="Path_4390" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M4.57-10.4l5.35,0V-7S4.71-3.7,4.66-3.82,2.74-7,2.74-7Z" transform="translate(32.71 31.41)" />
              <path id="Path_4391" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M14.14-.47l-2.4,7.16L20.61,2.1V4.87l-8.87,7.3-1.92,8,6.41,5.15,1.92,12.45L22.7,43.1l6.62-15.27-.05-5.47-4.22-6.92-4.87-3,4.65-.25,4.49,4.34,4.33-2.89,3.26,5.09.77-2.55q1.09-1.95,2.38-3.8c.22.26,4.49,5,4.49,5l1.53-5.86,1,.45-.37-2.81.64-2.47,3.58-4.52-2-2.65,2.31-1.62.85,1.55,1-2.85,3.3-2.3L50-6.7,36-8.27,20.4-5.5Z" transform="translate(32.71 31.41)" />
              <path id="Path_4392" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M45.91,22.37s1.51-2,1.48-2.05Z" transform="translate(32.71 31.41)" />
              <path id="Path_4393" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M49.54,16.13a4.78,4.78,0,0,0,1-.34Z" transform="translate(32.71 31.41)" />
              <path id="Path_4394" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeMiterlimit="10" d="M52.32,22.22" transform="translate(32.71 31.41)" />
              <path id="Path_4395" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M42.76,24l-.65,1.5s-1.49-1.81-1.52-2Z" transform="translate(32.71 31.41)" />
              <path id="Path_4396" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M42.36,32.3l8.71-4.43,2.35,6.68L51,41.07,47,36.63l-4.91.06Z" transform="translate(32.71 31.41)" />
              <path id="Path_4398" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M54.33,42.28l1.25-1.73Z" transform="translate(32.71 31.41)" />
              <path id="Path_4399" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M9.16,3.76l1-3.37Z" transform="translate(32.71 31.41)" />
              <path id="Path_4400" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" strokeLinejoin="round" d="M16.61-8.38,15.26-7Z" transform="translate(32.71 31.41)" />
              <path id="Path_4411" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" d="M-10.69,51.54a44.21,44.21,0,0,1,40.43-78.3" transform="translate(32.71 31.41)" />
              <path id="Path_4412" fill="none" stroke={this.state.geogBtnColor} strokeWidth="4" d="M-10.85,44.69l1.34,7.63-7.63,1.34" transform="translate(32.71 31.41)" />
            </svg>
            <div className="sideBarBtnTxt">STD. GEOGRAPHY</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'explore')}
            handleHoverOff={this.handleHoverOff.bind(this, 'explore')}
            handleHover={this.handleHover.bind(this, 'explore')}
          >
            <svg id="exploreIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 90.78 87.07">
              <path id="Path_4389" d="M-25.48-11l.05-2,4-4,4.15.05L-5-18.94-5.07-17-7-14.93l3.85,4.07-8,8.23,0,3.87,8,2L1,7.29l-2,4.42L-3,13.34-5,23.51l-4.29-2-1.74-10-4.19-4.17,2-4-6-6.1-2.2-8.08Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4390" d="M-.08-19l5,0v3.15S0-12.79,0-12.9s-1.8-3-1.8-3Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4391" d="M8.85-10.9,6.61-5.25l8.28-3.62v2.18L6.61-.93,4.81,5.41l6,4.07,1.8,9.81,4.24,4.17,6.18-12L23,7.1,19,1.64,14.5-.69l4.34-.2L23,2.53l4-2.28,3,4,.72-2a34.27,34.27,0,0,1,2.23-3c.2.2,4.19,3.92,4.19,3.92l1.42-4.62.92.35L39.24-3.3l.6-1.95,3.35-3.57L41.34-10.9l2.15-1.27.79,1.22,1-2.25L48.32-15l-6-.8L29.26-17,14.69-14.87Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4392" d="M38.51,7.12s1.41-1.86,1.38-1.92Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4393" d="M41.9,2.06a3.74,3.74,0,0,0,1-.32Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4394" d="M44.5,6.9" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeMiterlimit="10" strokeWidth="4" />
              <path id="Path_4395" d="M35.57,8.14,35,9.54s-1.39-1.7-1.42-1.84Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4396" d="M35.2,13.38l8.13-4.14,2.2,6.24-2.25,6.08-3.79-4.14-4.59,0Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4398" d="M46.37,22.69l1.17-1.61Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4399" d="M4.19-7.43l.9-3.14Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4400" d="M11.15-17.15,9.9-15.9Z" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4109" d="M50.82,32.89l3.73.74V-28.08L31-32.24,8.27-23.35-10.9-30.51l-22.83,9.08V40.28l22.83-9.09L2.87,35.61" transform="translate(34.98 33.53)" fill="none" stroke={this.state.exploreBtnColor} strokeWidth="4" />
              <path id="Path_4114" d="M29.5,35.3A2.53,2.53,0,1,0,27,32.78,2.52,2.52,0,0,0,29.5,35.3Z" transform="translate(34.98 33.53)" stroke={this.state.exploreBtnColor} fill="none" strokeWidth="4" />
              <path id="Path_4115" d="M29.56,52.85s-9.67-14.63-9.67-19.94a9.65,9.65,0,0,1,9.67-9.63h0c5.06,0,10,4.5,9.68,9.63-.14,2.5-1.7,6-3.22,9C34.6,44.73,33.21,47,33.21,47" transform="translate(34.98 33.53)" stroke={this.state.exploreBtnColor} fill="none" strokeWidth="4" />
            </svg>
            <div className="sideBarBtnTxt">EXPLORE MAP</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'print')}
            handleHoverOff={this.handleHoverOff.bind(this, 'print')}
            handleHover={this.handleHover.bind(this, 'print')}
          >
            <svg id="printIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 99.24 88.27">
              <path d="M302.31,129.81a8,8,0,0,0-8-8H246.2a8,8,0,0,0-8,8v21.34a.19.19,0,0,0,.18.19h63.84a.1.1,0,0,0,.09-.1Z" transform="translate(-220.63 -120.56)" fill="none" stroke={this.state.printBtnColor} strokeMiterlimit="10" strokeWidth="4"/>
              <rect x="17.56" y="58.9" width="64.12" height="28.12" fill="none" stroke={this.state.printBtnColor} strokeMiterlimit="10" strokeWidth="4"/>
              <polygon points="1.25 30.78 1.25 71.09 17.56 71.09 17.56 58.9 81.68 58.9 81.68 71.09 97.99 71.09 97.99 30.78 1.25 30.78" fill="none" stroke={this.state.printBtnColor} strokeMiterlimit="10" strokeWidth="4"/>
              <line x1="30.08" y1="68.74" x2="69.17" y2="68.74" fill="none" stroke={this.state.printBtnColor} strokeMiterlimit="10" strokeWidth="4"/>
              <line x1="30.08" y1="77.48" x2="69.17" y2="77.48" fill="none" stroke={this.state.printBtnColor} strokeMiterlimit="10" strokeWidth="4"/>
            </svg>
            <div className="sideBarBtnTxt">PRINT</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'legend')}
            handleHoverOff={this.handleHoverOff.bind(this, 'legend')}
            handleHover={this.handleHover.bind(this, 'legend')}
          >
            <svg id="legendIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 94.5 94.5">
              <path id="Path_4151" d="M55.9-26.44a9.46,9.46,0,0,0-9.46-9.46H-26.64a9.45,9.45,0,0,0-9.46,9.46V46.64a9.46,9.46,0,0,0,9.46,9.46H46.44a9.47,9.47,0,0,0,9.46-9.46h0V.15" transform="translate(37.35 37.15)" fill="none" stroke={this.state.legendBtnColor} strokeWidth="4" />
              <path id="Path_4152" d="M36-11.9h-28" transform="translate(37.35 37.15)" fill="none" stroke={this.state.legendBtnColor} strokeWidth="4" />
              <path id="Path_4153" d="M36,12.1h-28" transform="translate(37.35 37.15)" fill="none" stroke={this.state.legendBtnColor} strokeWidth="4" />
              <path id="Path_4154" d="M36,36.1h-28" transform="translate(37.35 37.15)" fill="none" stroke={this.state.legendBtnColor} strokeWidth="4" />
              <path id="Path_4157" d="M-14.11,40.07a6,6,0,0,0,6-6,6,6,0,0,0-6-6,6,6,0,0,0-5.95,5.95,6,6,0,0,0,5.95,6Z" transform="translate(37.35 37.15)" fill="none" stroke={this.state.legendBtnColor} strokeWidth="4" />
              <path id="Path_4158" d="M-19.81-12.55l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke={this.state.legendBtnColor} strokeWidth="4" />
              <path id="Path_4159" d="M-19.81,10.45l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke={this.state.legendBtnColor} strokeWidth="4" />
            </svg>
            <div className="sideBarBtnTxt">LEGEND</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'startOver')}
            handleHoverOff={this.handleHoverOff.bind(this, 'startOver')}
            handleHover={this.handleHover.bind(this, 'startOver')}
          >
            <svg id="startOverIcon" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 93.61 90.15">
              <path id="Path_3881" d="M11.35,17.88H31.51V53.67H11.35Z" transform="translate(37.28 35.17)" fill="none" stroke={this.state.startOverBtnColor} strokeWidth="4" />
              <path id="Path_3879" d="M47.64,14.08V53.73H-28.58V14.08" transform="translate(37.28 35.17)" fill="none" stroke={this.state.startOverBtnColor} strokeWidth="4" />
              <path id="Path_3880" d="M-12.59,17.86H-.43V30H-12.59Z" transform="translate(37.28 35.17)" fill="none" stroke={this.state.startOverBtnColor} strokeWidth="4" />
              <path id="Path_3882" d="M-36.47,5.3l46-38.84,46,38.84" transform="translate(37.28 35.17)" fill="none" stroke={this.state.startOverBtnColor} strokeWidth="4" />
            </svg>
            <div className="sideBarBtnTxt">START OVER</div>
          </PanelButton>
        </div>
        <div>
          {this.state.searchAddressShow || this.state.clickShow || this.state.geogShow || this.state.drawShow || this.props.mapRestPoint || this.state.printShow
            ? <GetReport
              key={this.state.searchAddressShow + "_" + this.state.clickShow + "_" + this.state.geogShow + "_" + this.state.drawShow + "_" + this.state.printShow}
              togglePanel={this.togglePanel}
              updatePointsArray={this.props.updatePointsArray}
              updatePolyArray={this.props.updatePolyArray}
              updateMapCenter={this.props.updateMapCenter}
              updateMapZoom={this.props.updateMapZoom}
              updateRadiusVal={this.props.updateRadiusVal}
              radiusVal={this.props.radiusVal}
              minSalesFilterVal={this.props.minSalesFilterVal}
              maxSalesFilterVal={this.props.maxSalesFilterVal}
              points={this.props.points}
              searchAddressShow={this.state.searchAddressShow}
              clickShow={this.state.clickShow}
              geogShow={this.state.geogShow}
              printShow={this.state.printShow}
              drawShow={this.state.drawShow}
              updateMapAddPt={this.props.updateMapAddPt}
              updateFilterVal={this.props.updateFilterVal}
              updateMapDrawPt={this.props.updateMapDrawPt}
              polys={this.props.polys}
              mapRestPoint={this.props.mapRestPoint}
              updateMapRestPoint={this.props.updateMapRestPoint}
              driveTime={this.props.driveTime}
              updateDriveTime={this.props.updateDriveTime}
              segmentFilterVal={this.props.segmentFilterVal}
              subsegmentFilterVal={this.props.subsegmentFilterVal}
              categoryFilterVal={this.props.categoryFilterVal}
              chainFilterVal={this.props.chainFilterVal}
              mapBounds={this.props.mapBounds}
              mapMoved={this.props.mapMoved}
              updateMapMoved={this.props.updateMapMoved}
              mapPrintCenter={this.props.mapPrintCenter}
              updateEditControlLayer={this.props.updateEditControlLayer}
              updateExplore={this.updateExplore}
            ></GetReport>
            : null
          }
        </div>
        {this.state.exploreShow ?
          <SearchAddress
            updateMapCenter={this.props.updateMapCenter}
          />
          : null
        }
        <div>
          {this.state.legendShow
            ? <Legend
              togglePanel={this.togglePanel}
            ></Legend>
            : null
          }
        </div>
        {this.state.showOpeningBtns ?
          <OpeningBtns
            togglePanel={this.togglePanel}
            updateOpeningBtns={this.updateOpeningBtns}
          />
          : null}
          {this.props.mapCenter.length === 0 ?
          <div className="options-Panel">
            <div className="loader"></div>
          </div>
          : null}
      </div>
    )
  }
}

export default SideBar;